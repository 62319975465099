//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");

@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";

h1,
.h1 {
  font-size: 1.8rem;
  font-weight: 900;

  @include media-breakpoint-up(lg) {
    font-size: 2.2rem;
  }
}

h2,
.h2 {
  font-size: 1.6rem;
  font-weight: 900;

  @include media-breakpoint-up(lg) {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: 1.5rem;
  font-weight: 900;

  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }
}

h4,
.h4 {
  font-size: 1.4rem;
  font-weight: 900;

  @include media-breakpoint-up(lg) {
    font-size: 1.6rem;
  }
}

h5,
.h5 {
  font-size: 1.2rem;
  font-weight: 900;

  @include media-breakpoint-up(lg) {
    font-size: 1.4rem;
  }
}

h6,
.h6 {
  font-size: 1.1rem;
  font-weight: 900;

  @include media-breakpoint-up(lg) {
    font-size: 1.2rem;
  }
}

a {
  color: #00a0e9;

  &:hover {
    text-decoration: none;
  }
}

.btn {
  &:focus {
    box-shadow: 2px 3.5px 5px 0px rgb(0 0 0 / 29%);
  }
}

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------
.text-weight-black {
  font-weight: 900;
}

body {
  font-family: "Noto Sans JP", sans-serif;
}

header {
  background: url(../img/bg-silver.webp) no-repeat 0 30%;
  background-size: cover;
  box-shadow: 0 3px 7px 0 rgb(4 0 0 / 5%);
  position: relative;

  .logo {
    width: 120px;
    margin: 1rem;
    display: inline-block;
  }
}

footer {
  color: #acaca9;
  text-align: center;

  a {
    color: #acaca9;

    &.icon_link:after {
      margin-left: 0.3em;
      content: fa-content($fa-var-arrow-up-right-from-square);
      @extend %fa-icon;
      @extend .fa-solid;
    }
  }

  .footer-list {
    margin: 2rem 0.5rem 1rem 2rem;
    padding-left: 0;

    li {
      list-style: none;
      text-align: left;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: center;
      margin-left: 0;

      li {
        text-align: center;
        margin: 0 1rem;
      }
    }
  }
}

.bg-yellow {
  background: #fff002;
}

.bg-stripegray {
  background: url(../img/ptn_grey_stripe.webp) repeat 0 0;
  overflow: hidden;

  &.bg-stripe-first {
    background-position: 0 0;
  }
}

.bg-stripeyellow {
  background: #fff002 url(../img/ptn_yellow_stripe.webp) repeat 0 0;
  overflow: hidden;
}

//checker parts
.checker-form {
  width: 100%;
  font-size: 1.2rem;
  padding: 1rem;
  border-radius: 0;
  border-color: #5d5d5d;
}

.checker-button {
  background-color: rgb(255, 240, 0);
  font-size: 1.2rem;
  padding: 0.8rem 4rem;
  font-weight: 900;
  box-shadow: 2px 3.5px 5px 0px rgba(0, 0, 0, 0.29);
  transition: all 0.1s ease;

  &:hover {
    background-color: rgb(255, 240, 0);
    transform: translate(2px, 3.5px);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.29);
  }
}

.checker-text {
  color: red;

  &:hover {
    color: darken(red, 5%);
    text-decoration: none;
  }
}

.btn-yellow {
  background: #fff000;
  padding: 1rem 2rem 1rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 700;
  box-shadow: 2px 3.5px 5px 0px rgba(0, 0, 0, 0.29);
  display: inline-block;
  margin: 1rem 0;
  transition: all 0.1s ease;
  position: relative;

  @include media-breakpoint-up(lg) {
    font-size: 1.2rem;
  }

  &:after {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    content: fa-content($fa-var-angle-right);
    @extend %fa-icon;
    @extend .fa-solid;
    margin-left: 0.3em;
  }

  &:hover {
    transform: translate(2px, 3.5px);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.29);
    background: #fff000;
  }

  &.disabled {
    background: #c7c7c7;
  }
}

.btn-free {
  text-align: left;
  line-height: 1.1;
  display: inline-flex;
  align-items: center;
  padding: .5rem 2rem .5rem 1.5rem;

  &:before {
    content: "";
    background: url(/img/30days.webp) no-repeat 50% 50%;
    background-size: 34px 34px;
    width: 34px;
    height: 34px;
    display: block;
    margin-right: 1rem;

    @include media-breakpoint-up(lg) {
      background-size: 64px 64px;
      width: 68px;
      height: 68px;
    }
  }
}

.btn-img {
  border: transparent solid 5px;
  padding: 0;
  border-radius: 20px;
  overflow: hidden;

  &:hover {
    border-color: #fff000;
  }

  &.no-border-radius {
    border-radius: 0;
  }
}

.btn-image {
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  font-size: 1rem;
  padding: 0.5rem 2rem 1rem 2rem;
  position: relative;
  flex-direction: column;

  img {
    max-width: 100px;
    height: 68px;
  }

  margin-left: auto;
  margin-right: auto;

  &.end {
    @include media-breakpoint-up(lg) {
      margin-left: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 1rem 1.5rem;
    flex-direction: row;

    img {
      margin-right: 1rem;
    }
  }
}

.btn-question,
.btn-request {
  display: flex;
  text-decoration: none;

  &:before {
    margin-right: 0.3em;
    color: #000;
    line-height: var(--bs-body-line-height);
  }

  span {
    text-decoration: underline;
  }

  &:hover {
    span {
      text-decoration: none;
    }
  }
}

.btn-question {
  &:before {
    content: fa-content($fa-var-circle-question);
    @extend %fa-icon;
    @extend .fa-solid;
  }
}

.btn-request {
  &:before {
    content: fa-content($fa-var-comment-dots);
    @extend %fa-icon;
    @extend .fa-solid;
  }
}

.btn-back {
  margin: 0.3rem 1rem;

  a {
    &:before {
      content: fa-content($fa-var-chevron-left);
      @extend %fa-icon;
      @extend .fa-solid;
      margin-right: 0.3em;
      color: #000;
    }
  }
}

.modal-content {
  border: none;
}

.modalResult {
  .btn-close {
    background: none;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 1;
    width: 2rem;
    height: 2rem;

    &:after {
      background: #000;
      color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      content: fa-content($fa-var-xmark);
      @extend %fa-icon;
      @extend .fa-solid;
      font-size: 1.5rem;
    }
  }

  .modal-header {
    background: #1d316b;
    color: #fff;
    justify-content: center;

    .modal-title {
      text-align: center;
      font-weight: 900;
      padding: 1.5em 1rem;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      line-height: 1.2;

      img {
        margin-right: 1rem;
      }

      div {
        text-align: left;
      }

      @include media-breakpoint-up(lg) {
        font-size: 1.8rem
      }
    }
  }

  .modal-body {
    padding: 0;

    .checkcontent {
      padding: 1rem;
      margin: 5px;

      .check-report {
        font-size: 1.2rem;
        font-weight: 900;
        text-align: center;
        padding: 1em 1rem;
        margin: 0;
        line-height: 1.2;

        @include media-breakpoint-up(lg) {
          font-size: 1.6rem;
        }
      }
    }

    .alert-title {
      font-size: 1.15rem;
      font-weight: 900;
      margin: 1rem 0;

      @include media-breakpoint-up(lg) {

        font-size: 1.3rem;

      }
    }

    .counterplan {
      padding: 1rem;

      .counterplan-content {
        margin: 0;


        @include media-breakpoint-up(lg) {
          margin: 0 1rem;
          padding: 1rem;
        }

        h1 {
          margin: 2rem 0;
          text-align: center;
        }

        h2 {
          font-size: 1.3rem;
          text-align: center;

          @include media-breakpoint-up(lg) {
            font-size: 1.5rem;
          }

          span {
            background: linear-gradient(transparent 30%,
                #fff000 30%,
                #fff000 75%,
                transparent 75%);
          }
        }

        height: 100%;
        padding: .5rem;
        border-radius: 1rem;
        background: url(../img/ptn_grey_stripe.webp) repeat 0 0;
        overflow: hidden;

        p {
          font-size: 1.15rem;

          strong {
            font-size: 1.15rem;
          }

          &.fukidashi {
            background: #fff;
            padding: 1rem;
            border-radius: 10px;
            font-weight: 900;
          }

          @include media-breakpoint-up(lg) {
            font-size: 1.3rem;

            strong {
              font-size: 1.3rem;
            }
          }
        }

        &.is-resolution {
          h2 {
            span {
              background: #fff000;
              padding: 0.7em 2em;
              display: inline-block;
            }
          }
        }
      }
    }

    .bg-white {
      margin: 1rem 0;
      padding: 1rem;
    }

    .bg-yellow {
      padding: 1rem;
    }
  }

  &.is-safety {
    .is-danger-part {
      display: none;
    }

    .modal-header {
      background-size: auto auto;
      background-color: rgba(18, 196, 0, 1);
      background-image: repeating-linear-gradient(135deg, transparent, transparent 6px, rgba(42, 202, 26, 1) 6px, rgba(42, 202, 26, 1) 12px);

      .modal-title {
        color: #fff;

        span {
          display: block;

          span {
            color: #000;
            background: #fff002;
            display: inline-block;
            padding: 0 1rem;
            margin: .5rem 0;
          }
        }

        &:before {
          content: fa-content($fa-var-check);
          @extend %fa-icon;
          @extend .fa-solid;
          margin-right: 0.3em;
          font-size: 1.5em;
        }
      }
    }

    .modal-body {
      .checkcontent {
        background: #e1ffde;
      }
    }
  }

  &.is-danger {
    .is-safety-part {
      display: none;
    }

    .modal-header {
      background: #fff000;
      color: #000;
      border: 27px solid #fff000;
      border-width: 0 0 27px 0;
      border-image: repeating-linear-gradient(135deg,
          transparent,
          transparent 27px,
          rgba(0, 0, 0, 1) 27px,
          rgba(0, 0, 0, 1) 54px) 25;
    }

    .modal-body {
      .checkcontent {
        background: #cecece;
      }
    }
  }
}

#checker {
  position: relative;

  #checker-box {
    visibility: visible;
  }

  #urlcheckProgress {
    display: none;
  }

  #urlError {
    color: #ff0000;
    font-weight: 900;
    margin: 0.5rem;
    display: none;
  }

  &.is-check {
    #checker-box {
      visibility: hidden;
    }

    #urlcheckProgress {
      display: block;
    }
  }

  &.is-error {
    #targetUrlEdit {
      border-color: #cf000e;
    }

    #urlError {
      display: block;
    }
  }
}

@keyframes progress-anim {
  to {
    width: 100%;
  }
}

.checkurl {
  text-align: center;
  font-weight: 900;
  border-bottom: 1px solid #000;
  word-break: break-all;
}

.hightlight-yellow-50 {
  background: linear-gradient(transparent 60%, #fff000 60%);
}

.hightlight-yellow-100 {
  background: linear-gradient(transparent 0%, #fff000 0%);
}

.about-sagiwall {
  h2 {
    color: #636363;
    text-align: center;
  }

  h3 {
    text-align: center;
    margin: 1em 0;

    img {
      margin: 0.5em;
    }
  }
}

.ex_fraudulent {
  border-radius: 10px;
  margin: 2rem 0;
  background: #fff;
  overflow: hidden;

  h1 {
    background: #eeeeee;
    padding: 2rem 2rem;
    font-weight: 900;
    vertical-align: middle;
    line-height: 1;
    font-size: 1.8rem;
  }

  p {
    margin: 0.5rem 3rem;
    font-size: 1.4rem;
  }

  img {
    display: block;
    margin: 0 auto;
  }
}

.alert-box {
  margin: 1rem 0;
  border: 1px dashed #636363;
  padding: 1rem;

  h1 {
    font-size: 1.25rem;
    font-weight: 900;
    text-align: center;
    margin-bottom: 1rem;

    .fa-circle-info {
      color: #ff0000;
      margin: 0 0.5rem;
    }
  }

  p {
    margin: 0.5rem 0;
  }

  @include media-breakpoint-up(lg) {
    margin: 3rem 0;
    padding: 2rem;
  }
}

.about {
  padding-top: 3rem;
  padding-bottom: 3rem;

  h1 {
    margin: 2rem auto;
    text-align: center;

    img {
      width: 200px;
      margin: 0 1rem;
    }

    .hightlight {
      background: linear-gradient(transparent 30%,
          #fff000 30%,
          #fff000 75%,
          transparent 75%);
      display: inline;
      padding: 0.3rem;
      margin: 0 0.3rem;
    }
  }
}

.about-cell {
  .img_func {
    display: block;
    margin: 1rem auto;
    max-width: 100%;
  }

  .check {
    width: 100%;
    display: block;
    max-width: 274px;
    margin: 0 auto;

    &+.check {
      border-top: 1px dotted #636363;
    }
  }

  .about-cell-text {
    display: inline-flex;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.2;

    span {
      font-weight: 900;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
    }
  }

  .about-cell-content {
    display: flex;
    align-items: stretch;
    height: 100%;
    flex-direction: column;

    .img_func {
      width: 50%;

      @include media-breakpoint-up(md) {
        width: 75%;
      }
    }
  }

  .about-cell-content+.about-cell-content {
    position: relative;
    margin-top: 60px;

    &:before {
      content: "";
      width: 50px;
      height: 50px;
      background: url(../img/plus.webp) no-repeat 0 0;
      position: absolute;
      left: calc(50% - 25px);
      top: 0;
      margin-top: -55px;

      @include media-breakpoint-up(md) {
        left: -25px;
        margin-top: 0;
        top: 28%;
        margin-top: -25px;
      }

      @include media-breakpoint-up(xl) {
        top: 30%;
      }
    }

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  &.step {
    .about-cell-content+.about-cell-content {
      &:before {
        background: url(../img/note_arrow.webp) no-repeat 0 0;
        transform: rotate(90deg);

        @include media-breakpoint-up(md) {
          transform: rotate(0);
          top: 50%;
        }
      }
    }
  }
}


//---------------------------------------------
// 3.Components
//---------------------------------------------
#about-checker {
  h1 {
    margin: 1em 0;
    font-size: 1.4rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
  }

  img {
    margin-bottom: 0.3rem;
  }

  ul {
    font-size: 1rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.2rem;
    }

    font-weight: 700;
    list-style: none;
    padding-left: 0;

    li {
      padding-left: 1.2rem;
      text-indent: -0.6rem;
      margin: 0.3rem 0;

      span {
        color: #ff0000;
      }

      &:before {
        content: fa-content($fa-var-tag);
        @extend %fa-icon;
        @extend .fa-solid;
        margin-right: 0.2rem;
        color: #cdcdcd;
      }
    }
  }
}

ol.list-step {
  padding-left: 0;


  &>li {
    list-style-type: none;
    counter-increment: cnt;
    position: relative;
    font-size: 1.15rem;
    font-weight: bold;
    padding: 1rem .5rem 1rem 2.5rem;
    margin: 1rem 0;
    border-bottom: 1px dashed #d1d1d1;

    &:last-child {
      border: none;
    }

    .text-blank {
      font-weight: 900;
    }

    @include media-breakpoint-up(lg) {
      margin: 1rem 0 1rem 1rem;
      font-size: 1.3rem;
      padding: 1rem 2rem 2rem 2.5rem;
    }

    &:before {
      content: counter(cnt);
      display: inline-block;
      background: #fff000;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 1rem;
      left: 0;
    }
  }
}

#use-step {
  h1 {
    margin: 2rem 0;
    text-align: center;
  }

  p {
    margin: 0;
  }

  ol.list-step {
    margin: 2rem;
  }

  ol.decimal {
    list-style: decimal;
  }
}

#recommended {
  margin: 4rem 0 2rem;

  h1 {
    margin: 2rem 0;
    text-align: center;
  }

  .recommended-sec {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      text-align: center;
      margin: 1rem 0;
      font-size: 1.1rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }

    img {
      max-width: 80%;
    }
  }
}

//トライアングルアニメーション

$triColor: #cf000e;

@keyframes pulse {
  0% {
    opacity: 1;
  }

  16.666% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#urlcheckProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.triangles {
  transform: translate(0%, 0%);
  height: 81px;
  width: 90px;
  margin: 0 auto;
}

.tricaption {
  position: absolute;
  bottom: -1.2rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 80%;
  width: 8em;
  color: $triColor;
  font-weight: 900;
}

.tri {
  position: absolute;
  animation: pulse 750ms ease-in infinite;
  border-top: 27px solid $triColor;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 0px;

  &.invert {
    border-top: 0px;
    border-bottom: 27px solid $triColor;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }

  &:nth-child(1) {
    left: 30px;
  }

  &:nth-child(2) {
    left: 15px;
    top: 27px;
    animation-delay: -125ms;
  }

  &:nth-child(3) {
    left: 30px;
    top: 27px;
  }

  &:nth-child(4) {
    left: 45px;
    top: 27px;
    animation-delay: -625ms;
  }

  &:nth-child(5) {
    top: 54px;
    animation-delay: -250ms;
  }

  &:nth-child(6) {
    top: 54px;
    left: 15px;
    animation-delay: -250ms;
  }

  &:nth-child(7) {
    top: 54px;
    left: 30px;
    animation-delay: -375ms;
  }

  &:nth-child(8) {
    top: 54px;
    left: 45px;
    animation-delay: -500ms;
  }

  &:nth-child(9) {
    top: 54px;
    left: 60px;
    animation-delay: -500ms;
  }
}

#floatSearch {
  position: fixed;
  bottom: -100%;
  right: 1rem;
  transition: 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &.show {
    bottom: 1rem;
  }
}

/*eula.html*/
.number {
  margin-left: 2.5rem;
  padding-left: 0;

  li {
    list-style-type: none;
    counter-increment: cnt;
    position: relative;

    &:before {
      content: "("counter(cnt) ")";
      display: inline-block;
      margin-left: -2.5em;
      /* サイトに合せて調整 */
      width: 2em;
      /* サイトに合せて調整 */
      text-align: right;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.eula {
  margin: 2rem 0;

  h1,
  h2 {
    margin: 1em 0;
  }

  h2 {
    font-size: 1.3rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
  }
}

.note {
  margin-bottom: 3rem;

  h1 {
    background: #fff002;
    padding: .5em;
    text-align: center;
    margin-bottom: 1.5em;

    &:before {
      margin-right: 0.3em;
      color: #000;
      line-height: var(--bs-body-line-height);
      content: fa-content($fa-var-circle-question);
      @extend %fa-icon;
      @extend .fa-solid;
    }

    &#request {
      &:before {
        content: fa-content($fa-var-comment-dots);
      }
    }
  }
}

.note-text {
  font-weight: bold;

  @include media-breakpoint-up(md) {
    font-size: 1.1rem;
  }
}

.table-note {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #333;
  border-left: 1px solid #333;
  margin: 2rem 0;

  .table-tr {
    width: 100%;
  }

  .table-header,
  .table-content {

    width: 100%;
    display: flex;
  }

  .table-header-title,
  .table-content-col {
    width: 50%;
    padding: .5rem 1rem;
    text-align: center;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: auto;
      max-width: 100%;
    }
  }



  .table-header-title-none {
    display: none;
    background: #d1d1d1;
  }

  .table-content-title {
    background: #d1d1d1;
    width: 100%;
    padding: .5rem 1rem;
    font-size: 115%;
    font-weight: 900;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;

    div {
      br {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }

      &:after {
        margin-left: 0.3em;
        color: #000;
        line-height: var(--bs-body-line-height);
        content: fa-content($fa-var-circle-question);
        @extend %fa-icon;
        @extend .fa-solid;
      }
    }
  }

  @include media-breakpoint-up(lg) {

    .table-header,
    .table-tr {
      width: 25%;
      flex-direction: column;
    }

    .table-content-title {
      height: 100px;
    }

    .table-header-title,
    .table-content-col {
      width: 100%;
      height: 100px;



    }

    .table-header-title-none {
      display: block;
    }

    .table-content {
      flex-direction: column;
    }

  }
}
